<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.measurements')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="vocabulary-table">
        <div class="table-header sticky-vocabulary-header">
          <div class="table-header-text justify-content-start" style="flex-basis: 100%">
            <div class="d-inline-block mr-3">
              <sort-arrows :sort-string="sortString" :column="'created_at,'" @click="sortTable('created_at,', $event)" />
              {{ $t('table.created') }}
            </div>
            <div class="d-inline-block">
              <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
              {{ $t('sideBar.measurements') }}
            </div>
          </div>
        </div>

        <SpinnerLoader :loading="measurementsStatus" />

        <DropDownTable
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :child-item="row.target_audiences"
          :show-id="true"
          edit-permission="measurement_company.update"
          add-permission="target_audience.create"
          delete-permission="measurement_company.delete"
          edit-inner-permission="target_audience.update"
          delete-inner-permission="target_audience.delete"
          @showModalAddChild="showModalAddTarget"
          @showModalEditChild="showModalEditTarget"
          @showModalDeleteChild="showModalDeleteTarget"
          @showModalEditParent="showModalMeasurements"
          @showModalDeleteParent="showModalDeleteMeasurements"
        />

        <div v-if="!tableData.length && measurementsStatus === 'success'" class="mt-2 d-flex justify-content-center">{{ $t('table.noContent') }}</div>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('measurement_company.create')" variant="primary" @click="showModalMeasurements()">
          {{ $t('table.addNewMeasurements') }}
        </b-button>
      </div>
      <!--FOOTER-->
    </div>

    <!--MODAL ADD/EDIT MEASUREMENT-->
    <b-modal
      id="add-edit-measurements"
      ref="add-edit-measurements"
      :title="modalMeasurement.type === 'add' ? $t('table.addNewMeasurements') : $t('table.editMeasurements')"
      :ok-disabled="$v.modalMeasurement.$invalid"
      :ok-title="modalMeasurement.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidMeasurementThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidMeasurementThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-measurement-title">
          <b-form-input
            id="input-measurement-title"
            v-model.trim="modalMeasurement.name"
            :placeholder="$t('table.enterTitle')"
            autofocus
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD/EDIT MEASUREMENT-->

    <!--MODAL ADD/EDIT TARGET-->
    <b-modal
      id="add-edit-target"
      ref="add-edit-target"
      :title="modalTarget.type === 'add' ? $t('table.addTarget') : $t('table.editTarget')"
      :ok-disabled="$v.modalTarget.$invalid"
      :ok-title="modalTarget.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidTargetThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidTargetThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-target-name">
          <b-form-input id="input-target-name" v-model.trim="modalTarget.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD/EDIT TARGET-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import DropDownTable from '../components/DropDownTable';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows.vue';

export default {
  name: 'Measurements',
  components: { PaginateWrapper, DropDownTable, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      targetData: '',
      filterPage: 1,
      selectedPerPage: '100',

      modalMeasurement: {
        type: 'add',
        name: '',
      },
      modalTarget: {
        type: 'add',
        name: '',
      },
      isModalBusy: false,
      isModalLoading: false,
      sortString: '-created_at,',
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      measurements: 'getMeasurements',
      measurementsStatus: 'getMeasurementsStatus',
      modalEditMeasurements: 'getModalEditMeasurements',
    }),
    canEdit: function () {
      return this.$checkPermissions('measurement_company.update');
    },
    canDelete: function () {
      return this.$checkPermissions('measurement_company.delete');
    },
  },
  validations: {
    modalMeasurement: {
      name: { required },
    },
    modalTarget: {
      name: { required },
    },
  },
  watch: {
    measurements() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.measurements') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getMeasurementsInfo();
  },
  destroyed() {
    this.$store.commit('clearMeasurementsList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_MEASUREMENTS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        include: 'targetAudiences',
      });
      this.updateQuery();
    },
    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.measurements) {
        this.tableData = this.measurements.data;
        this.paginationData = this.measurements.pagination;
        if (this.filterPage > this.measurements.pagination.last_page) {
          this.filterPage = this.measurements.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getMeasurementsInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    clearMeasurementFields() {
      this.modalMeasurement = {
        type: 'add',
        name: '',
      };
    },

    clearTargetFields() {
      this.modalTarget = {
        type: 'add',
        name: '',
      };
      this.targetData = '';
    },

    // modal add new
    async showModalMeasurements(data) {
      this.isModalLoading = true;
      this.clearMeasurementFields();
      data ? (this.modalMeasurement.type = 'edit') : (this.modalMeasurement.type = 'add');
      this.$refs['add-edit-measurements'].show();
      if (data) {
        await this.$store.dispatch('GET_MEASUREMENTS_ID', data.data);
        this.modalMeasurement.name = this.modalEditMeasurements?.name;
      }
      this.isModalLoading = false;
    },

    async addEditMeasurement() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalMeasurement.type === 'add' ? undefined : this.modalEditMeasurements?.id,
        name: this.modalMeasurement.name,
      };
      await this.$store.dispatch(this.modalMeasurement.type === 'add' ? 'POST_MEASUREMENTS' : 'PUT_MEASUREMENTS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalMeasurement.type === 'add' ? this.$i18n.t('alert.addMeasurements') : this.$i18n.t('alert.editMeasurements'),
            text: this.modalMeasurement.name,
          });
          this.$refs['add-edit-measurements'].hide();
          this.clearMeasurementFields();
          this.paramsData();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteMeasurements(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteMeasurements')} "${data?.data?.name}"?`, {
          title: this.$i18n.t('table.deleteMeasurements'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-measurement-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.data?.id;
            const name = data?.data?.name;
            this.$store.dispatch('DELETE_MEASUREMENTS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteMeasurements'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    // modal add target
    async showModalAddTarget(data) {
      this.isModalLoading = true;
      this.clearTargetFields();
      this.modalTarget.type = 'add';
      this.$refs['add-edit-target'].show();
      this.targetData = data;
      this.isModalLoading = false;
    },
    // modal edit  target
    async showModalEditTarget(data) {
      this.isModalLoading = true;
      this.clearTargetFields();
      this.modalTarget.type = 'edit';
      this.$refs['add-edit-target'].show();
      this.targetData = data;
      this.modalTarget.name = data.name;
      this.isModalLoading = false;
    },
    async addEditTarget() {
      this.isModalBusy = true;
      const formData = {
        measurement_company_id: this.modalTarget.type === 'add' ? this.targetData.id : this.targetData?.measurement_company_id,
        id: this.modalTarget.type === 'add' ? undefined : this.targetData.id,
        name: this.modalTarget.name,
      };
      await this.$store.dispatch(this.modalTarget.type === 'add' ? 'POST_TARGET' : 'PUT_TARGET_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalTarget.type === 'add' ? this.$i18n.t('alert.addTarget') : this.$i18n.t('alert.editTarget'),
            text: this.modalTarget.name,
          });
          this.$refs['add-edit-target'].hide();
          this.clearTargetFields();
          this.paramsData();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete Group
    async showModalDeleteTarget(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteTarget')} "${data?.name}"?`, {
          title: this.$i18n.t('table.deleteTarget'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-group-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.id;
            const name = data?.name;
            this.$store.dispatch('DELETE_TARGET_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteTarget'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidMeasurementThenEnter() {
      if (!this.$v.modalMeasurement.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalMeasurement.type === 'add'
              ? `${this.$i18n.t('table.confirmAddMeasurements')} "${this.modalMeasurement.name}"?`
              : `${this.$i18n.t('table.confirmEditMeasurements')} "${this.modalMeasurement.name}"?`,
            {
              title: this.modalMeasurement.type === 'add' ? this.$i18n.t('table.addMeasurements') : this.$i18n.t('table.editMeasurements'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-class-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditMeasurement();
          })
          .catch((err) => {});
      }
    },
    checkIfValidTargetThenEnter() {
      if (!this.$v.modalTarget.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalTarget.type === 'add'
              ? `${this.$i18n.t('table.confirmAddTarget')} "${this.modalTarget.name}"?`
              : `${this.$i18n.t('table.confirmEditTarget')} "${this.modalTarget.name}"?`,
            {
              title: this.modalTarget.type === 'add' ? this.$i18n.t('table.addTarget') : this.$i18n.t('table.editTarget'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-class-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditTarget();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="scss"></style>
